






























































import { Component, Vue, Watch } from 'vue-property-decorator'
import { Route } from 'vue-router'
import store from '@/store/store'
import ChangeLogModal from './ChangeLog.modal.vue'
import { EventBus } from '@/utils/event-bus'
import { Product } from '@/store/modules/product'
import { GvdUpdate } from '@/store/modules/gvd'
import { SlideDeckUpdate } from '@/store/modules/slideDeck'

@Component({
  components: {
    ChangeLogModal
  }
})
export default class ProductDashboard extends Vue {
  get product () {
    return store.getters.getActiveProduct
  }

  get gvds () {
    return this.product.gvds
  }

  get slideDecks () {
    return this.product.slideDecks
  }

  get hasResources () {
    return this.$store.getters.getResourcesForProductKey(this.product.key).length
  }

  breadcrumbItems: any[] = [
    {
      text: 'Home',
      to: { name: 'home' }
    },
    {
      html: this.product.name,
      to: { name: 'product' }
    }
  ]

  @Watch('product')
  async productChanged (product: Product) {
    await this.$store.dispatch('loadProductResourcesForMarket', product.key)
    this.breadcrumbItems = [
      {
        text: 'Home',
        to: { name: 'home' }
      },
      {
        html: this.product.name,
        to: { name: 'product' }
      }
    ]
  }

  activeChangeLog: GvdUpdate[] | SlideDeckUpdate[] = []

  openGvdChangeLogModal (key: string) {
    this.$store.dispatch('loadGvdChangeLog', key).then((updates: GvdUpdate[]) => {
      this.activeChangeLog = updates
      this.$bvModal.show('change-log-modal')
    })
  }

  openSlideDeckChangeLogModal (key: string) {
    this.$store.dispatch('loadSlideDeckChangeLog', key).then((updates: SlideDeckUpdate[]) => {
      this.activeChangeLog = updates
      this.$bvModal.show('change-log-modal')
    })
  }

  async beforeRouteUpdate (to: Route, from: Route, next: Function) {
    await store.dispatch('loadProduct', { productKey: to.params.productKey, marketKey: store.getters.getSelectedMarket })
    next()
  }

  async beforeRouteEnter (to: Route, from: Route, next: Function) {
    await store.dispatch('loadProduct', { productKey: to.params.productKey, marketKey: store.getters.getSelectedMarket })
    next()
  }

  async mounted () {
    EventBus.$on('market-selected', async () => {
      if (this.$route.name !== 'product') {
        return
      }
      await this.$nextTick()
      if (!this.product || !this.product.key) {
        return
      }
      await this.$store.dispatch('loadProduct', { productKey: this.product.key, marketKey: store.getters.getSelectedMarket })
    })
  }
}
